import messagesEn from './en/en.json'
import messagesAr from './ar/ar.json'
import messagesEl from './el/el.json'
import messagesEs from './es/es.json'
import messagesDe from './de/de.json'
import messagesIt from './it/it.json'
import messagesFr from './fr/fr.json'

const MessagesMessages = {
  en: { messages: { ...messagesEn } },
  ar: { messages: { ...messagesAr } },
  el: { messages: { ...messagesEl } },
  es: { messages: { ...messagesEs } },
  de: { messages: { ...messagesDe } },
  it: { messages: { ...messagesIt } },
  fr: { messages: { ...messagesFr } },
}

export default MessagesMessages
