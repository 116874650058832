import mainEn from './en/en.json'
import mainAr from './ar/ar.json'
import mainEl from './el/el.json'
import mainEs from './es/es.json'
import mainDe from './de/de.json'
import mainIt from './it/it.json'
import mainFr from './fr/fr.json'

const MainMessages = {
  en: mainEn,
  ar: mainAr,
  el: mainEl,
  es: mainEs,
  de: mainDe,
  it: mainIt,
  fr: mainFr,
}

export default MainMessages
