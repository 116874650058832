import { createI18n } from 'vue-i18n'
import en from '../../localization/en/en.json'
import ar from '../../localization/ar/ar.json'
import el from '../../localization/el/el.json'
import es from '../../localization/es/es.json'
import de from '../../localization/de/de.json'
import it from '../../localization/it/it.json'
import fr from '../../localization/fr/fr.json'
import { DEFAULT_LOCALE } from './i18n.constants'
import numberFormats from './rules/numbers'
import datetimeFormats from './rules/datetime'
import pluralRules from './rules/pluralization'

export * from './i18n.constants'
export * from './i18n.types'

export default createI18n({
  legacy: false,
  locale: DEFAULT_LOCALE.code,
  globalInjection: true,
  messages: {
    en,
    ar,
    el,
    es,
    de,
    it,
    fr,
  },
  pluralRules,
  numberFormats,
  datetimeFormats,
  fallbackLocale: DEFAULT_LOCALE.code,
})
