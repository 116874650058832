import paymentEn from './en/en.json'
import paymentAr from './ar/ar.json'
import paymentEl from './el/el.json'
import paymentEs from './es/es.json'
import paymentDe from './de/de.json'
import paymentIt from './it/it.json'
import paymentFr from './fr/fr.json'

const PaymentMessages = {
  en: { payment: { ...paymentEn } },
  ar: { payment: { ...paymentAr } },
  el: { payment: { ...paymentEl } },
  es: { payment: { ...paymentEs } },
  de: { payment: { ...paymentDe } },
  it: { payment: { ...paymentIt } },
  fr: { payment: { ...paymentFr } },
}

export default PaymentMessages
