import type { TripTypes } from '../components/TripSelector/TripSelector.types'
import type { InterestCity } from './model/interestCity'
import type { Hotel, RoomRate } from './model/hotelInfo'
import type { DestinationLocation } from '@/modules/Search/types/model/destination'
import type {
  Car as CarModel,
  Flight as FlightModel,
} from '@/modules/Search/types/model/search'
import type { Airport as AirportLocation } from '@/modules/Profile/types/model/airport'
import type { Currency } from '@/modules/Core/types/model/currency'

export interface Passengers {
  adults: number
  children: number
}

interface FlightOptions {
  classOfService: number
  departureTime: string
  arrivalTime: string
}

interface CarOptions {
  type: string
  pickUpDate: string
  dropOffDate: string
}

interface HotelOptions {
  roomType: string
  starRating: number
  mealsIncluded: string
  checkInDate: string
  checkOutDate: string
  hotelLocation: DestinationLocation | undefined
}

export interface Options {
  flight: FlightOptions
  car: CarOptions
  hotel: HotelOptions
}

export interface Flight {
  origin: string
  destination: string
  dateOfTravel: string
  durationOfLocation: string
  arrivalTimeList: string[]
  departureTimeList: string[]
}

export interface Form {
  flights: Flight[]
  tripType: TripTypes
  departureTime: string
  arrivalTime: string
  origin: string
  destination: string
  daysOfDuration: number
  passengers: Passengers
  bags: number
  options: Options
  interestsAndActivities: string[]
}

export type SearchType = 'Destination' | 'Attributes' | 'Events'

export type Modes = 'flight' | 'hotel' | 'car'

export type Passenger = 'Adult' | 'Child'

export interface ModesOfTravel {
  daysToStay?: number
  modes: Array<Modes>
}

export interface SearchPanelMain extends ModesOfTravel {
  tripType: TripTypes
  searchBy: SearchType
  attributesDestination?: DestinationLocation
  origin?: AirportLocation
  destination?: AirportLocation
  departureTime: string
  arrivalTime: string
  daysOfDuration?: number
  bags: number
  flightOptionsNumberOfAdults: number
  flightOptionsNumberOfChildren: number
  childrenAges: Array<number | null>
  interestsAndActivities?: string[]
}

export interface SearchPanelDestinationItem {
  origin?: AirportLocation | DestinationLocation
  destination?: AirportLocation | DestinationLocation
  departureTime?: string
  arrivalTime?: string
}

export type SearchPanelDestinationItems = Array<SearchPanelDestinationItem>

export interface SearchPanelFlight {
  origin?: AirportLocation
  destination?: AirportLocation
  dateOfTravel: string
  durationOfLocation: string
}

export interface SearchPanelHotel {
  checkInDate?: string
  checkOutDate?: string
  hotelLocation?: DestinationLocation
}

export interface SearchPanelCar {
  pickUpDate?: string
  dropOffDate?: string
}

export interface SearchPanelAttributesFlight {
  origin?: AirportLocation
  destination?: DestinationLocation
  dateOfTravel: string
  durationOfLocation: string
}

export interface SearchPanelFlightPreferredTime {
  departureTimeList: string[]
  arrivalTimeList: string[]
}

export enum HotelImageSize {
  Small = 'small',
  Medium = 'medium',
  Bigger = 'bigger',
  Xl = 'xl',
  Xxl = 'xxl',
  Original = 'original',
}

export enum CarType {
  Compact = 'Compact',
  Economy = 'Economy',
  Electric = 'Electric',
  'Intermediate/Mid-size' = 'IntermediateMidSize',
  Standard = 'Standard',
  'Full-Size' = 'FullSize',
  Premium = 'Premium',
  Luxury = 'Luxury',
  SUV = 'SUV',
  Conventible = 'Convertible',
  Minivan = 'Minivan',
  'Pickup Truck' = 'PickupTruck',
  Hybrid = 'Hybrid',
  Any = 'Any',
}

export interface SearchPanelCarOptions {
  type?: CarType
  carPreferences: Array<SearchPanelCar>
}

export enum HotelRoomType {
  StandardRoom = 'StandardRoom',
  DeluxeRoom = 'DeluxeRoom',
  SuperiorRoom = 'SuperiorRoom',
  ExecutiveRoom = 'ExecutiveRoom',
  FamilyRoom = 'FamilyRoom',
  AccessibleRoom = 'AccessibleRoom',
  Suite = 'Suite',
  JuniorSuite = 'JRSuite',
  Any = 'Any',
}

export enum HotelViewType {
  PoolView = 'PoolView',
  SpecialView = 'SpecialView',
  CityView = 'CityView',
  GardenView = 'GardenView',
  Any = 'Any',
}

export enum HotelMealsIncluded {
  None = 'None',
  Breakfast = 'Breakfast',
  HalfBoard = 'HalfBoard',
  FullBoard = 'FullBoard',
  AllInclusive = 'AllInclusive',
  SelfCatering = 'SelfCatering',
  Any = 'Any',
}

export enum HotelBedType {
  DoubleBed = 'DoubleBed',
  TwoDoubleBeds = 'TwoDoubleBeds',
  KingBed = 'KingBed',
  SofaBed = 'SofaBed',
  SingleBed = 'SingleBed',
  TwinBed = 'TwinBed',
  TwoTwinBeds = 'TwoTwinBeds',
  QueenBed = 'QueenBed',
  TwoQueenBeds = 'TwoQueenBeds',
  BunkBed = 'BunkBed',
  Any = 'Any',
}

export enum HotelSortBy {
  Price = 'Price',
  StarRating = 'StarRating',
  Alphabetic = 'Alphabetic',
}

export interface SearchPanelHotelOptions {
  roomType: HotelRoomType | undefined
  starRating: number
  mealsIncluded: HotelMealsIncluded
  hotelPreferences: Array<SearchPanelHotel>
}

export enum FlightClassOfService {
  Economy = 1,
  PremiumEconomy = 2,
  Business = 3,
  First = 4,
}

export enum AirCraftCatering {
  SoftDrink = 'Soft Drink',
  StandardDrink = 'Standard Drink',
}

export enum MaximumOfStops {
  None = '0',
  UpToOne = '1',
  UpToTwo = '2',
}

export enum FilteredFlightListSortBy {
  Disabled = 0,
  Price = 1,
  Duration = 2,
  DepartureTime = 3,
  ArrivalTime = 4,
  FitScore = 5,
}

export interface SearchPanelFlightOptions {
  minimumClassofService: FlightClassOfService | undefined
  flightTimeRanges: SearchPanelFlightPreferredTime[]
  numberStops: MaximumOfStops
}

export interface FlightFilterTimeRange {
  iata: string
  departureTime: Maybe<string>
  arrivalTime: Maybe<string>
  floatingHours?: number
}

export interface SearchPanelFlightFilters {
  flightType: FlightClassOfService | undefined
  items: FlightFilterTimeRange[]
  stopFilter: MaximumOfStops
  sortBy: FilteredFlightListSortBy | null
  airlines: string[]
}

export interface SearchPanelAirCraftsFilters {
  isTwinEngine: string | boolean
  hasFlightAttendantService: string | boolean
  hasAirCondition: string | boolean
  hasLavatory: string | boolean
  isPetAllowed: string | boolean
  isDaylightRestricted: string | boolean
  cateringType: AirCraftCatering | string
}

export interface SearchPanelHotelFilter {
  sortBy?: HotelSortBy
  starRating?: number
  roomType?: HotelRoomType
  bedType?: HotelBedType
  viewType?: HotelViewType
  meals?: HotelMealsIncluded
  hotels?: string[]
}

export interface SearchPanel {
  searchId?: string
  guestId?: string
  main: SearchPanelMain
  destinations: SearchPanelFlight[]
  interestsAndActivities: string[]
  attributeOptions: InterestCity[]
}

export interface SearchPanelHistory extends SearchPanel {
  currency: Currency
  flightOptions: SearchPanelFlightOptions
  hotelOptions: SearchPanelHotelOptions
  carOptions: SearchPanelCarOptions
  isRoundTrip: boolean
  isMultiDestination: boolean
  isSearchByDestination: boolean
  isSearchByAttributes: boolean
  destinationList: SearchPanelDestinationItems
  roundTripDestinationList: SearchPanelDestinationItems
}

export enum FilteredCarListSortBy {
  CarType = 'CarType',
  Price = 'Price',
  Capacity = 'Capacity',
}

export enum SearchOrFilterMode {
  Search,
  Filter,
}

export interface SearchPanelCarFilters {
  sortCarType?: CarType
  sortBy?: FilteredCarListSortBy
  capacity?: number
  companies?: string[]
}

export interface RoomRateWithQuantity {
  roomRate: RoomRate
  quantity: number
}

export interface HotelInfoToReserve extends Hotel {
  roomRates: RoomRateWithQuantity[]
}

export interface ItinerarySummary {
  isOpen: boolean
  items: Array<CarModel | FlightModel | HotelInfoToReserve>
}

export interface HotelInfoWithRates
  extends Omit<Hotel, 'rooms' | 'country' | 'city'> {
  rooms: Array<RoomRate>
  country?: string
  city?: string
}
