import profileEn from './en/en.json'
import profileAr from './ar/ar.json'
import profileEl from './el/el.json'
import profileEs from './es/es.json'
import profileDe from './de/de.json'
import profileIt from './it/it.json'
import profileFr from './fr/fr.json'

const ProfileMessages = {
  en: { profile: { ...profileEn } },
  ar: { profile: { ...profileAr } },
  el: { profile: { ...profileEl } },
  es: { profile: { ...profileEs } },
  de: { profile: { ...profileDe } },
  it: { profile: { ...profileIt } },
  fr: { profile: { ...profileFr } },
}

export default ProfileMessages
