import authEn from './en/en.json'
import authAr from './ar/ar.json'
import authEl from './el/el.json'
import authEs from './es/es.json'
import authDe from './de/de.json'
import authIt from './it/it.json'
import authFr from './fr/fr.json'

const AuthMessages = {
  en: { auth: { ...authEn } },
  ar: { auth: { ...authAr } },
  el: { auth: { ...authEl } },
  es: { auth: { ...authEs } },
  de: { auth: { ...authDe } },
  it: { auth: { ...authIt } },
  fr: { auth: { ...authFr } },
}

export default AuthMessages
