/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import type { Router } from 'vue-router'
import router from './router'
import HelpModule from '@/modules/Help'
import ProfileModule from '@/modules/Profile'
import MoreModule from '@/modules/More'
import MessagesModule from '@/modules/Messages'
import MainModule from '@/modules/Core'
import AuthModule from '@/modules/Auth'
import TripsModule from '@/modules/Trips'
import SearchModule from '@/modules/Search'
import SupportModule from '@/modules/Support'
import BookModule from '@/modules/Book'
import LoyaltyModule from '@/modules/Loyalty'
import PaymentModule from '@/modules/Payment'
import IndexModule from '@/modules/Index'
import i18n, { SUPPORTED_LOCALES } from '@/modules/Core/plugins/i18n'

type ModuleRouteAdder = (router: Router) => void

interface ModuleLocalization {
  [key: string]: any
}

interface Module {
  router: ModuleRouteAdder
  localization: ModuleLocalization
}

interface Modules {
  [key: string]: any
}

// Merge Localizations And Routers
function registerModule(module: Module) {
  if (module.localization) {
    for (const locale in module.localization) {
      i18n.global.mergeLocaleMessage(locale, module.localization[locale])
    }
  }

  if (module.router) {
    module.router(router)
  }
}

export function registerModules(modules: Modules) {
  Object.keys(modules).forEach((moduleKey) => {
    const module = modules[moduleKey]
    registerModule(module)
  })
}

const locales: never[] = []

export async function registerLocales(codes: string[]) {
  for (const locale of codes) {
    if (locale !== 'en') {
      const path = `/node_modules/date-fns/locale/${locale}`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      locales[locale] = await import(/* @vite-ignore */ path)
    }
  }
}

export function getLocale(locale: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return locales[locale].default
}

registerLocales(SUPPORTED_LOCALES.map((locale) => locale.code))

const INCLUDES_INDEX_PAGE = import.meta.env.VITE_INCLUDES_INDEX_PAGE

registerLocales(SUPPORTED_LOCALES.map((locale) => locale.code))

registerModules({
  help: HelpModule,
  profile: ProfileModule,
  more: MoreModule,
  messages: MessagesModule,
  auth: AuthModule,
  trips: TripsModule,
  search: SearchModule,
  support: SupportModule,
  book: BookModule,
  loyalty: LoyaltyModule,
  payment: PaymentModule,
  ...(INCLUDES_INDEX_PAGE === 'true' && { index: IndexModule }),
  // ⚠️ Don't register any modules after the MainModule.
  main: MainModule,
})
