import loyaltyEn from './en/en.json'
import loyaltyAr from './ar/ar.json'
import loyaltyEl from './el/el.json'
import loyaltyEs from './es/es.json'
import loyaltyDe from './de/de.json'
import loyaltyIt from './it/it.json'
import loyaltyFr from './fr/fr.json'

const LoyaltyMessages = {
  en: { loyalty: { ...loyaltyEn } },
  ar: { loyalty: { ...loyaltyAr } },
  el: { loyalty: { ...loyaltyEl } },
  es: { loyalty: { ...loyaltyEs } },
  de: { loyalty: { ...loyaltyDe } },
  it: { loyalty: { ...loyaltyIt } },
  fr: { loyalty: { ...loyaltyFr } },
}

export default LoyaltyMessages
