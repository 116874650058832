import moreEn from './en/en.json'
import moreAr from './ar/ar.json'
import moreEl from './el/el.json'
import moreEs from './es/es.json'
import moreDe from './de/de.json'
import moreIt from './it/it.json'
import moreFr from './fr/fr.json'

const MoreMessages = {
  en: { more: { ...moreEn } },
  ar: { more: { ...moreAr } },
  el: { more: { ...moreEl } },
  es: { more: { ...moreEs } },
  de: { more: { ...moreDe } },
  it: { more: { ...moreIt } },
  fr: { more: { ...moreFr } },
}

export default MoreMessages
