export default {
  en: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'USD',
    },
  },
  el: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'EUR',
    },
  },
  ar: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'RUB',
    },
  },
}
