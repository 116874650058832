import type { Step, Telephone } from '@journey/ui-kit'
import type { Component } from 'vue'
import type { Genders } from '@/modules/Core/constants'

export enum BookingStepType {
  Flight = 'flight',
  Hotel = 'hotel',
  Car = 'car',
  Payment = 'payment',
  Summary = 'summary',
}

export enum BookHotelGuestType {
  Adult = 'Adult',
  Child = 'Child',
  Infant = 'Infant',
}

export type FeaturedStep = Step & {
  component: Component
  stepType: BookingStepType
  isActive: boolean
}

export type StepsCompletedType = {
  [key in BookingStepType]: boolean
}

export interface StepProgressProps {
  index: number
  prevStep: Maybe<Step>
  nextStep: Maybe<Step>
  isLoading?: boolean
  isPayment?: boolean
}
export interface StepProgressEmits {
  (event: 'changeStep', value: number): void
  (event: 'pay'): void
}

export type PassengerType = 'Adult' | 'Child' | 'Infant'

export interface FlightTravelerInformation {
  passengerType: PassengerType
  firstName: string
  lastName: string
  middleName: string
  passportNumber: string
  sexOnPassport: 'M' | 'F' | undefined
  expiryDate: string
  gender: Genders | undefined
  country: string
  birthDate: string
  isCompleted: boolean
}

export type FlightTravelerInformationWithCompletion =
  FlightTravelerInformation & { isCompleted: boolean }

export interface FlightContactDetails {
  contactEmail: string
  phone: Telephone
}

export interface RoomGuestInformation {
  firstName: string
  lastName: string
  contactEmail: string
}

export interface RoomCheckInCheckOutInformation {
  rateKey: string
  checkInTime: string
  checkOutTime: string
  roomName: string
  guest?: RoomGuestInformation
}

export interface HotelCheckingPreferences {
  checkIn: string
  checkOut: string
  rooms: RoomCheckInCheckOutInformation[]
}

export interface HotelTravelerInformation {
  contactEmail: string
  firstName: string
  lastName: string
  country: string
  phone: Telephone
}

export interface HotelGuestInformation {
  firstName: string
  lastName: string
  birthDate: string
  type: BookHotelGuestType
  sexOnPassport: 'M' | 'F' | undefined
}

export interface HotelGuestLeadInformation extends HotelGuestInformation {
  country: string
  phone: Maybe<Telephone>
  contactEmail: string
  panNumber?: number
  panCompanyName: string
}

export type HotelGuestOrLeadInformationWithCompletion = (
  | HotelGuestInformation
  | HotelGuestLeadInformation
) & { isCompleted: boolean } & Record<string, unknown>

export type HolderWithCompletion = HotelGuestLeadInformation & {
  isCompleted: boolean
}

export interface CarDriverInformation {
  contactEmail: string
  firstName: string
  lastName: string
  birthDate: string
  country: string
  city: string
  address: string
  postalCode: string
  phone: Telephone
}

export interface CarPickUpDropOffTimePreferences {
  pickUpDate: string
  pickUpTime: string
  dropOffDate: string
  dropOffTime: string
}

export interface ContactInfoStoreType {
  carPickUpDropOff: CarPickUpDropOffTimePreferences
  activeStep: BookingStepType
  isLoadingFetchQueries: boolean
  isOpenNotAvailableModal: boolean
}

export interface CarBooking extends CarDriverInformation {
  bookProvider: string
  searchTerm: string
  pickupDate: string
  dropoffDate: string
  carCode: string
  supplierCode: string
  rateQualifier: string
}

export interface BookCar {
  bookingNumber: string
  supplierRef: string
}

export interface RecentTraveler {
  firstName: string
  lastName: string
  birthDate: string
  sexOnPassport: 'M' | 'F' | undefined
  contactEmail?: string
  phone?: Maybe<Telephone>
  country?: string
}
